import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')];

export const server_loads = [0];

export const dictionary = {
	"/": [3],
	"/availableToolsOld": [~5],
	"/availableTools": [~4],
	"/billing": [~6],
	"/cancelled": [~7],
	"/faq-app": [8],
	"/legal-documentation": [9],
	"/login": [10],
	"/products": [11],
	"/products/[productId]": [12,[],[2]],
	"/profile": [~13],
	"/recoveryEmail": [14],
	"/recoveryPassword": [15],
	"/settings": [~16],
	"/signupWithEmail": [18],
	"/signup": [17],
	"/terms": [19]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};